export default function App() {
  return (
    <div className="text-sm h-full w-full font-serif p-8">
      <div className="flex flex-col justify-between h-full">
        <dl className="">
          {[
            {name: "Chess Coordinate Trainer", url: "/ChessCoordTrainer/", 
              desc: "Tool to help memorize the squares on a chess board."},
            {name: "SnakeGameJS", url: "/SnakeGameJs/", 
              desc: "The classic snake game written in javascript. Honestly, not the best version of this game."},
            {name: "Old jakethoffman.com", url: "/ArchivedWebpages/OldJakethoffman/public/", 
              desc: "A previous version of my personal website. Currently a little broken since I've converted to React."},
            {name: "Waves", url: "/Waves/", 
              desc: "Type something."},
            {name: "Words", url: "/Words/", 
              desc: "Write some words and I'll put them in a database."},
            {name: "Cube", url: "/Cube/", 
              desc: "CSS cube will follow you."},
            {name: "MandelbrotRust", url: "/MandelbrotRust/", 
              desc: "Mandelbrot set visualization written in rust and compiled as WASM module"},
            ].map((item) => 
              <a href={item.url} className=" hover:text-main-text-hover">
                <div className="border-t-[0.1rem] p-4 border-main-code-bg">
                  <dt className="font-bold">{item.name}</dt>
                  <dd className="pl-2">     {item.desc}</dd>
                </div>
              </a> 
            )}
        </dl>
        <div>
            {[
              {url: "https://github.com/jakehffn", title: "@GitHub"},
              {url: "https://www.linkedin.com/in/jacob-t-hoffman/", title: "@LinkedIn"},
              // {url: "https://twitter.com/jakehffn", title: "@Twitter"}
            ].map((link) => {return <a href={link.url} className="p-2 text-sm font-bold hover:text-main-text-hover">{link.title}</a>})}
        </div>
      </div>
    </div>
  );
}